.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.50rem + 2px);
    padding: 2px 10px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #ced4da;
    background-color: #343a40;
    background-clip: padding-box;
    border: 1px solid #6c757d;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-control {
        transition: none; } }
    .form-control::-ms-expand {
      background-color: transparent;
      border: 0; }
    .form-control:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 #ced4da; }
    .form-control:focus {
      color: #ced4da;
      background-color: #343a40;
      border-color: #9bbcff;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(27, 104, 255, 0.25); }
    .form-control::placeholder {
      color: #6c757d;
      opacity: 1; }
    .form-control:disabled, .form-control[readonly] {
      background-color: #6c757d;
      opacity: 1; }

      textarea.form-control {
        height: auto; }
        .form-control {
            display: inline-block;
            width: auto;
            vertical-align: middle; }

            .input-group > .form-control + .form-control,
            .input-group > .form-control + .custom-select,
            .input-group > .form-control + .custom-file,
            .input-group > .custom-select + .form-control,
            .input-group > .custom-file + .form-control {
              margin-left: -1px; }
          .input-group > .form-control:focus,
          .input-group > .custom-select:focus,
          .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
            z-index: 3; }