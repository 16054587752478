.btn {
    display: inline-block;
    font-weight: 400;
    color: #ced4da;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .btn {
        transition: none; } }
    .btn:hover {
      color: #ced4da;
      text-decoration: none; }
    .btn:focus, .btn.focus {
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(27, 104, 255, 0.25); }
    .btn.disabled, .btn:disabled {
      opacity: 0.65; }
    .btn:not(:disabled):not(.disabled) {
      cursor: pointer; }

      .btn-primary {
        color: #ffffff;
        background-color: #1b68ff;
        border-color: #1b68ff; }
        .btn-primary:hover {
          color: #ffffff;
          background-color: #0052f4;
          border-color: #004ee7; }
        .btn-primary:focus, .btn-primary.focus {
          color: #ffffff;
          background-color: #0052f4;
          border-color: #004ee7;
          box-shadow: 0 0 0 0.2rem rgba(61, 127, 255, 0.5); }
        .btn-primary.disabled, .btn-primary:disabled {
          color: #ffffff;
          background-color: #1b68ff;
          border-color: #1b68ff; }
        .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
        .show > .btn-primary.dropdown-toggle {
          color: #ffffff;
          background-color: #004ee7;
          border-color: #004ada; }
          .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
          .show > .btn-primary.dropdown-toggle:focus {
            box-shadow: 0 0 0 0.2rem rgba(61, 127, 255, 0.5); }
      
      .btn-secondary {
        color: #ffffff;
        background-color: #6c757d;
        border-color: #6c757d; }
        .btn-secondary:hover {
          color: #ffffff;
          background-color: #5a6268;
          border-color: #545b62; }
        .btn-secondary:focus, .btn-secondary.focus {
          color: #ffffff;
          background-color: #5a6268;
          border-color: #545b62;
          box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
        .btn-secondary.disabled, .btn-secondary:disabled {
          color: #ffffff;
          background-color: #6c757d;
          border-color: #6c757d; }
        .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
        .show > .btn-secondary.dropdown-toggle {
          color: #ffffff;
          background-color: #545b62;
          border-color: #4e555b; }
          .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
          .show > .btn-secondary.dropdown-toggle:focus {
            box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
      
      .btn-success {
        color: #212529;
        background-color: #3ad29f;
        border-color: #3ad29f; }
        .btn-success:hover {
          color: #ffffff;
          background-color: #2bbb8b;
          border-color: #28b183; }
        .btn-success:focus, .btn-success.focus {
          color: #ffffff;
          background-color: #2bbb8b;
          border-color: #28b183;
          box-shadow: 0 0 0 0.2rem rgba(54, 184, 141, 0.5); }
        .btn-success.disabled, .btn-success:disabled {
          color: #212529;
          background-color: #3ad29f;
          border-color: #3ad29f; }
        .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
        .show > .btn-success.dropdown-toggle {
          color: #ffffff;
          background-color: #28b183;
          border-color: #26a67b; }
          .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
          .show > .btn-success.dropdown-toggle:focus {
            box-shadow: 0 0 0 0.2rem rgba(54, 184, 141, 0.5); }
      
      .btn-info {
        color: #ffffff;
        background-color: #17a2b8;
        border-color: #17a2b8; }
        .btn-info:hover {
          color: #ffffff;
          background-color: #138496;
          border-color: #117a8b; }
        .btn-info:focus, .btn-info.focus {
          color: #ffffff;
          background-color: #138496;
          border-color: #117a8b;
          box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
        .btn-info.disabled, .btn-info:disabled {
          color: #ffffff;
          background-color: #17a2b8;
          border-color: #17a2b8; }
        .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
        .show > .btn-info.dropdown-toggle {
          color: #ffffff;
          background-color: #117a8b;
          border-color: #10707f; }
          .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
          .show > .btn-info.dropdown-toggle:focus {
            box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
      
      .btn-warning {
        color: #212529;
        background-color: #eea303;
        border-color: #eea303; }
        .btn-warning:hover {
          color: #ffffff;
          background-color: #c88903;
          border-color: #bc8102; }
        .btn-warning:focus, .btn-warning.focus {
          color: #ffffff;
          background-color: #c88903;
          border-color: #bc8102;
          box-shadow: 0 0 0 0.2rem rgba(207, 144, 9, 0.5); }
        .btn-warning.disabled, .btn-warning:disabled {
          color: #212529;
          background-color: #eea303;
          border-color: #eea303; }
        .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
        .show > .btn-warning.dropdown-toggle {
          color: #ffffff;
          background-color: #bc8102;
          border-color: #af7802; }
          .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
          .show > .btn-warning.dropdown-toggle:focus {
            box-shadow: 0 0 0 0.2rem rgba(207, 144, 9, 0.5); }
      
      .btn-danger {
        color: #ffffff;
        background-color: #dc3545;
        border-color: #dc3545; }
        .btn-danger:hover {
          color: #ffffff;
          background-color: #c82333;
          border-color: #bd2130; }
        .btn-danger:focus, .btn-danger.focus {
          color: #ffffff;
          background-color: #c82333;
          border-color: #bd2130;
          box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
        .btn-danger.disabled, .btn-danger:disabled {
          color: #ffffff;
          background-color: #dc3545;
          border-color: #dc3545; }
        .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
        .show > .btn-danger.dropdown-toggle {
          color: #ffffff;
          background-color: #bd2130;
          border-color: #b21f2d; }
          .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
          .show > .btn-danger.dropdown-toggle:focus {
            box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
      
      .btn-light {
        color: #ffffff;
        background-color: #495057;
        border-color: #495057; }
        .btn-light:hover {
          color: #ffffff;
          background-color: #383d42;
          border-color: #32373b; }
        .btn-light:focus, .btn-light.focus {
          color: #ffffff;
          background-color: #383d42;
          border-color: #32373b;
          box-shadow: 0 0 0 0.2rem rgba(100, 106, 112, 0.5); }
        .btn-light.disabled, .btn-light:disabled {
          color: #ffffff;
          background-color: #495057;
          border-color: #495057; }
        .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
        .show > .btn-light.dropdown-toggle {
          color: #ffffff;
          background-color: #32373b;
          border-color: #2c3034; }
          .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
          .show > .btn-light.dropdown-toggle:focus {
            box-shadow: 0 0 0 0.2rem rgba(100, 106, 112, 0.5); }
      
      .btn-dark {
        color: #ffffff;
        background-color: #212529;
        border-color: #212529; }
        .btn-dark:hover {
          color: #ffffff;
          background-color: #101214;
          border-color: #0a0c0d; }
        .btn-dark:focus, .btn-dark.focus {
          color: #ffffff;
          background-color: #101214;
          border-color: #0a0c0d;
          box-shadow: 0 0 0 0.2rem rgba(66, 70, 73, 0.5); }
        .btn-dark.disabled, .btn-dark:disabled {
          color: #ffffff;
          background-color: #212529;
          border-color: #212529; }
        .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
        .show > .btn-dark.dropdown-toggle {
          color: #ffffff;
          background-color: #0a0c0d;
          border-color: #050506; }
          .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
          .show > .btn-dark.dropdown-toggle:focus {
            box-shadow: 0 0 0 0.2rem rgba(66, 70, 73, 0.5); }