.titleTicket,
    .subtitleTicket {
        animation: translate-y-100 300ms cubic-bezier(0.165, 0.84, 0.44, 1.2) forwards;
        opacity: 0;
    }

    .titleTicket {
        margin-top: 0;
        margin-bottom: 0.5rem;
        font-size: 24px;
        animation-delay: 1100ms;
        color: #FFFFFF;
    }

    .subtitleTicket {
        margin-top: 0;
        font-size: 13px;
        color: #FFFFFF;
        animation-delay: 1150ms;
        line-height: 15px !important;
    }

    .cta-row {
        display: flex;
        justify-content: space-between;
        gap: 12px;
        width: 100%;
        animation: translate-y-100 600ms 2200ms cubic-bezier(0.19, 1, 0.22, 1) forwards;
        opacity: 0;
    }

    a.successBtn, a.secondaryBtn {
        position: relative;
        height: 32px;
        width: 100%;
        border: 0;
        border-radius: 5px;
        line-height: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #46a758;
        box-shadow: 0 1px 0 rgba(255, 255, 255, 0.2) inset,
            0 1px 3px 0 rgba(24, 25, 22, 0.1), 0 1px 2px -1px rgba(24, 25, 22, 0.1);
        border: 1px solid #2a7e3b;
        color: #ffffff;
        font-size: 13px;
        font-weight: 500;
        padding: 0.5rem 1rem;
        transition: transform 200ms cubic-bezier(0.165, -0.84, 0.44, 2),
            opacity 200ms ease, background-color 100ms ease;
        cursor: pointer;
    }

    a.successBtn:hover {
        background: #2a7e3b;
    }

    a.successBtn:active {
        transform: scale(0.98);
        box-shadow: 0 1px 0 rgba(0, 0, 0, 0.04) inset;
    }

    a.successBtn:active:before {
        opacity: 0.4;
    }

    a.secondaryBtn {
        color: #181916;
        background: white;
        box-shadow: var(--box-shadow);
        border: 1px solid #d9d9d6;
    }

    a.secondaryBtn:hover {
        background: #f8f8f6;
    }

    a.secondaryBtn:active {
        box-shadow: 0 1px 0 rgba(0, 0, 0, 0.04) inset;
    }

    .cardTicket {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 15px;
        background-color: #343a40;
        border-radius: 5px;
        box-sizing: border-box;
    }

    .cardTicket:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.01), 0 2px 4px rgba(0, 0, 0, 0.04);
        border-radius: 5px;
        clip-path: inset(0px -4px 0px -4px);
    }

    .cardTicket:nth-of-type(3):before {
        clip-path: inset(0px -4px -4px -4px);
    }

    .cardTicket:first-of-type {
        animation: translate-y-100 500ms 1000ms cubic-bezier(0.19, 1, 0.22, 1.2) forwards;
        opacity: 0;
        z-index: 0;
    }

    .cardTicket .iconTicket {
        width: 64px;
        height: 64px;
        border: 2px solid #ffffff;
        border-radius: 9999px;
        background: linear-gradient(to top, #f2f2f280, #e0e0e080);
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06) inset;
        display: grid;
        place-content: center;
        margin-bottom: 1rem;
    }

    .cardTicket .iconTicket:before {
        content: "\2713";
        display: grid;
        place-items: center;
        width: 56px;
        height: 56px;
        border-radius: 9999px;
        background-color: #ffffff;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03);
        font-family: "arial";
        font-size: 32px;
        color: #46a758;
    }

    .cardTicket:not(:first-of-type) {
        transform-origin: top;
        animation: unfold 500ms 1700ms cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
        -webkit-transform: perspective(500px) rotateX(-0.25turn);
        z-index: 1;
        opacity: 0.9;
    }

    .cardTicket:nth-of-type(3) {
        animation-delay: 1950ms;
        animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 1.4);
        z-index: 2;
    }

    .cardTicket:not(:first-of-type):after {
        position: absolute;
        top: -0.5px;
        left: 1%;
        content: "";
        width: 98%;
        height: 1px;
        border-top: 1px #d7dad7 dashed;
    }

    .ulTicket {
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 0rem !important;
        margin: 0;
        padding: 0;
        width: 100%;
    }

    .ulTicket > .liTicket {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .ulTicket > .liTicket .spanTicket {
        font-size: 12px;
        font-weight: 500;
        color: #FFFFFF;
    }

    .ulTicket > .liTicket .spanTicket:first-of-type {
        color: #FFFFFF;
    }

    @keyframes translate-y-100 {
        0% {
            transform: scale(0.9) translateY(0.5rem);
        }

        100% {
            opacity: 1;
        }
    }

    @keyframes unfold {
        to {
            transform: none;
            opacity: 1;
        }
    }