

.container {
    width: 100%;
    min-height: 100%;
    height: 100%;
    display: flex;
    background-color: #282c34;
    color: white;
    padding: 0;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.content {
    padding: 1rem 1.5rem;
    width: 100%;
    min-height: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

header.sc-ikkyvV.kEVxWd {
    display: none;
  }

  nav.sc-eeDSqt.dxEqmY {
      background-color: #343a40;
      color: #FFFFFF;
  }

.custom-table {
    background-color: #282c34 !important;
}

.col-12 {
    width: 100% !important;
}

.col-10 {
    width: 83.33% !important;
}

.col-8 {
    width: 66.66% !important;
}

.col-6 {
    width: 50% !important;
}

.col-4 {
    width: 33.33% !important;
}

.col-3 {
    flex: 0 0 25%;
    max-width: 25%; }

.col-2 {
    width: 16.66% !important;
}

.col {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
}

.col-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
}

.h2 {
    color: #FFFFFF;
    font-weight: 700;
    font-size: 1.75rem;
    line-height: 1.2;
}

.d-none {
    display: none;
}

.d-flex {
    display: flex;
}

.d-block {
    display: block;
}

.justify-content-center {
    justify-content: center;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px; 
}

.form-container {
    background: #343a40;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
}

.form-container h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #fff;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
    color: #fff;
}

.form-group textarea {
    width: 90%;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 5px;
    outline: none;
    transition: border-color 0.3s ease;
}

.form-group textarea:focus {
    border-color: #007bff;
}

.form-group textarea {
    resize: vertical;
    min-height: 100px;
}

.m-1 {
    margin: 0.25rem !important; }
  
.mt-1,
.my-1 {
margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
margin-left: 0.25rem !important; }

.m-2 {
    margin: 0.5rem !important; }
  
.mt-2,
.my-2 {
margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
margin-left: 0.5rem !important; }

.m-3 {
    margin: 1rem !important; }
  
.mt-3,
.my-3 {
margin-top: 1rem !important; }

.mr-3,
.mx-3 {
margin-right: 1rem !important; }

.mb-3,
.my-3 {
margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
margin-left: 1rem !important; }

.mb-4, .my-4 {
    margin-bottom: 1.5rem !important;
}

.mb-0, .my-0 {
    margin-bottom: 0 !important;
}

.align-items-center {
    align-items: center !important;
}

.text-center {
    text-align: center !important;
}

.circle {
    display: inline-flex;
    border-radius: 50%;
    align-items: center !important;
    text-align: center;
}

.circle-sm {
    width: 32px;
    height: 32px;
}

.bg-primary {
    background-color: #1b68ff !important;
}

.text-white {
    color: #ffffff !important;
}

.text-muted {
    color: #adb5bd !important;
}

.text-muted {
    font-weight: 300;
}


h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
    color: #dee2e6;
}

h3, .h3 {
    font-size: 1.53125rem;
}
@media (max-width: 1200px) {
    h3, .h3 {
        font-size: calc(1.27812rem + 0.3375vw);
    }
}

.h1, .h2, .h3,
h1, h2, h3 {
    font-weight: 700;
}

.h1, .h2, .h3, .h4, .h5, .h6,
h1, h2, h3, h4, h5, h6, strong {
  color: #dee2e6;
}

@media (max-width: 500px) {
    .form-container {
        padding: 20px;
    }

    .form-group input,
    .form-group textarea {
        font-size: 13px;
    }

    .col-12 {
        width: 100% !important;
    }
    
    .col-10 {
        width: 100% !important;
    }
    
    .col-8 {
        width: 100% !important;
    }
    
    .col-6 {
        width: 100% !important;
    }
    
    .col-4 {
        width: 100% !important;
    }
    
    .col-2 {
        width: 100% !important;
    }
}

.rdt_Pagination {
    background-color: #f8f9fa; /* Cambia el color de fondo */
    padding: 10px;
    border-radius: 5px;
  }
  
  /* Botones de paginación */
  .rdt_Pagination .rdt_PaginationBtn {
    background-color: #007bff; /* Color de fondo de los botones */
    color: white; /* Color del texto de los botones */
    border: none;
    padding: 5px 10px;
    margin: 0 5px;
    border-radius: 3px;
    cursor: pointer;
  }
  
  /* Hover y estado activo de los botones */
  .rdt_Pagination .rdt_PaginationBtn:hover {
    background-color: #0056b3; /* Color al hacer hover */
  }
  
  .rdt_Pagination .rdt_PaginationBtn--active {
    background-color: #28a745; /* Color para el botón activo */
  }
  
  /* Opcional: Estilo del contador de paginación */
  .rdt_Pagination .rdt_PaginationRowCount {
    color: #343a40; /* Cambia el color del texto */
    font-size: 14px;
    margin-left: 10px;
  }

  .loader-container {
    display: block;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  
  .loader {
    border: 4px solid #212529;
    border-top: 4px solid #3ad29f; /* Cambia el color del spinner */
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 0.5s linear infinite;
    margin: auto;
  }

  .loader-text {
    width: 100%;
    text-align: center;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  

  .dropdown-menu {
    z-index: 9999 !important;
    position: relative !important;
  }