.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #343a40;
    background-clip: border-box;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}

.shadow {
    box-shadow: 0 0.5rem 1rem rgba(26, 26, 26, 0.1) !important;
}

.shadow {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
}



p {
    margin-top: 0;
    margin-bottom: 1rem;
}

.small, small {
    font-size: 0.825rem;
    font-weight: 300;
    letter-spacing: .01em;
}

.text-muted {
    color: #adb5bd !important;
}

.text-muted {
    font-weight: 300;
}

.badge-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
}

.badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 600;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    /* transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; */
}