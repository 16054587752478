.banner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    padding: 0;
    text-align: center;
    height: 100vh;
    width: 100%;
    background: #050808;
    background-image: url('../img/portada-welcome.jpg'); /* ruta relativa desde el archivo CSS */
    background-size: cover;
    background-position: center;
  }
  .banner_content {
    width: 90%;
  }
  .banner > img {
    width: 40vw;
    transition: 0.1s linear;
    clip-path: circle(100%) !important;
  }
  
  .banner h2 {
    font-weight: 500;
    font-size: 30px;
    margin: 0 0 10px;
    color: #244c7c !important;
  }
  
  .banner p {
    margin: 0;
    line-height: 1.65;
    font-size: 17px;
    opacity: 0.7;
    color: white;
  }

  .waves-svg {
    position: absolute;
    left: 0;
    bottom: -15px;
    width: 100%;
    height: 30vw;
    max-height: 200px;
  }
  
  @media (width >= 420px) {
    .banner > img {
      width: 40vw;
    }
  }
  
  @media (width >= 648px) {
    .banner {
      text-align: left;
      flex-direction: row;
      justify-content: space-around;
      gap: 50px;
    }

    .banner_content {
      width: 25%;
    }
  
    .banner > img {
      width: 20vw;
    }

    .waves-svg {
      bottom: -45px;
    }
  }
  
  .waves > use {
    animation: move-forever 2s -2s linear infinite;
  }
  
  .waves > use:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 6s;
  }
  .waves > use:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 3s;
  }
  
  @keyframes move-forever {
    0% {
      transform: translate(-90px, 0%);
    }
    100% {
      transform: translate(85px, 0%);
    }
  }
  
  

  .login-box {
    width: 100%;
    padding: 1.2rem;
    background: transparent;
    box-sizing: border-box;
    box-shadow: 0 15px 25px rgba(0,0,0,.6);
    border-radius: 10px;
  }
  
  .login-box h2 {
    margin: 0 0 30px;
    padding: 0;
    color: #244c7c !important;
    text-align: center;
  }
  
  .login-box .user_box {
    position: relative;
  }
  
  .login-box .user_box input {
    width: 100%;
    padding: 10px 0;
    font-size: 16px;
    color: #34342c;
    margin-bottom: 0.5rem;
    border: none;
    border-bottom: 1px solid #34342c !important;
    outline: none;
    background: transparent;
  }
  .login-box .user_box label {
    position: absolute;
    top:0;
    left: 0;
    padding: 10px 0;
    font-size: 16px;
    color: #34342c !important;
    pointer-events: none;
    transition: .5s;
  }
  
  .login-box .user_box input:focus ~ label,
  .login-box .user_box input:valid ~ label {
    top: -20px;
    left: 0;
    color: #244c7c;
    font-size: 12px;
  }
  
  .login-box form button {
    position: relative;
    display: inline-block;
    padding: 5px;
    color: #244c7c;
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    margin-top: 0.5rem;
    letter-spacing: 4px;
    cursor: pointer;
    background-color: transparent;
    border: none;
  }
  
  .login-box button:hover {
    background: #244c7c;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #244c7c,
                0 0 25px #244c7c,
                0 0 50px #244c7c,
                0 0 100px #244c7c;
  }
  
  .login-box button span {
    position: absolute;
    display: block;
  }
  
  .login-box button span:nth-child(1) {
    top: 0;
    left: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, transparent, #244c7c);
    animation: btn-anim1 1s linear infinite;
  }
  
  @keyframes btn-anim1 {
    0% {
      left: -100%;
    }
    50%,100% {
      left: 100%;
    }
  }
  
  .login-box button span:nth-child(2) {
    top: -100%;
    right: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(180deg, transparent, #244c7c);
    animation: btn-anim2 1s linear infinite;
    animation-delay: .25s
  }
  
  @keyframes btn-anim2 {
    0% {
      top: -100%;
    }
    50%,100% {
      top: 100%;
    }
  }
  
  .login-box button span:nth-child(3) {
    bottom: 0;
    right: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg, transparent, #244c7c);
    animation: btn-anim3 1s linear infinite;
    animation-delay: .5s
  }
  
  @keyframes btn-anim3 {
    0% {
      right: -100%;
    }
    50%,100% {
      right: 100%;
    }
  }
  
  .login-box button span:nth-child(4) {
    bottom: -100%;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(360deg, transparent, #244c7c);
    animation: btn-anim4 1s linear infinite;
    animation-delay: .75s
  }
  
  @keyframes btn-anim4 {
    0% {
      bottom: -100%;
    }
    50%,100% {
      bottom: 100%;
    }
  }